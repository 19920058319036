import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchAllUsers, fetchAdminFestivalData } from '../../services/firebase/adminFunctions'
import { Line, Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js'
import './Admin.css'
import Tabs from '../Tabs/Tabs'
import { ref, get, set, push } from 'firebase/database'
import { database } from '../../config/firebase-config'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement)

// Create a new component for the users stats
const UsersStats = React.memo(({ users, unknownUserCount, handleNavigate }) => {
    const chartOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {grid: {color: 'rgba(255, 255, 255, 0.1)'}},
            y: {grid: {color: 'rgba(255, 255, 255, 0.1)'}}
        },
        maintainAspectRatio: false
    }

    // Define calculateWeeklyGrowth first
    const calculateWeeklyGrowth = (totalCounts) => {
        const pacificTimeOptions = { timeZone: "America/Los_Angeles" }
        const startDate = new Date("2024-07-31T00:00:00-07:00")
        const weekInMilliseconds = 7 * 24 * 60 * 60 * 1000
    
        const weeklyGrowth = []
        let currentDate = startDate
    
        weeklyGrowth.push({
            date: currentDate.toLocaleDateString("en-US", pacificTimeOptions),
            growth: 0
        })
    
        const startDateDataPoint = totalCounts.find(({ date }) =>
            new Date(new Date(date).toLocaleString("en-US", pacificTimeOptions)) >= currentDate
        )
        let lastWeekCount = startDateDataPoint ? startDateDataPoint.count : 0
    
        while (currentDate <= new Date(new Date().toLocaleString("en-US", pacificTimeOptions))) {
            const nextWeekDate = new Date(currentDate.getTime() + weekInMilliseconds)
    
            let closestDataPoint = totalCounts.find(({ date }) =>
                new Date(new Date(date).toLocaleString("en-US", pacificTimeOptions)) >= nextWeekDate
            )
    
            if (!closestDataPoint) {
                closestDataPoint = totalCounts[totalCounts.length - 1]
            }
    
            if (closestDataPoint) {
                const growthPercentage = lastWeekCount === 0 ? 0 : ((closestDataPoint.count - lastWeekCount) / lastWeekCount) * 100
                weeklyGrowth.push({
                    date: nextWeekDate.toLocaleDateString("en-US", pacificTimeOptions),
                    growth: growthPercentage
                })
                lastWeekCount = closestDataPoint.count
            }
    
            currentDate = nextWeekDate
        }
    
        return weeklyGrowth
    }

    // Then process user data
    const processUserData = React.useMemo(() => {
        const dateCounts = {}
        const totalCounts = []
        const startDate = new Date("2024-05-01")
    
        users.forEach(user => {
            let userDate = new Date(Number(user.createdAt) || user.createdAt)
            if (userDate > startDate) {
                const formattedDate = new Date(userDate.toLocaleString()).toLocaleDateString("en-US", { timeZone: "America/Los_Angeles" })
                dateCounts[formattedDate] = (dateCounts[formattedDate] || 0) + 1
            }
        })
    
        const sortedDates = Object.keys(dateCounts).sort((a, b) => new Date(a) - new Date(b))
        let cumulativeCount = 0
        sortedDates.forEach(date => {
            cumulativeCount += dateCounts[date]
            totalCounts.push({ date, count: cumulativeCount })
        })
    
        return { dateCounts, totalCounts, sortedDates }
    }, [users])

    // Then calculate weekly growth
    const weeklyGrowthData = React.useMemo(() => 
        calculateWeeklyGrowth(processUserData.totalCounts), 
        [processUserData.totalCounts]
    )

    // Finally prepare chart data
    const chartData = React.useMemo(() => ({
        totalUsersOverTimeData: {
            labels: processUserData.totalCounts.map(data => data.date),
            datasets: [{
                label: 'Total users',
                data: processUserData.totalCounts.map(data => data.count),
                fill: false,
                borderColor: 'white',
                tension: 0.1
            }]
        },
        totalUsersPerDayData: {
            labels: processUserData.sortedDates,
            datasets: [{
                label: 'New users',
                data: processUserData.sortedDates.map(date => processUserData.dateCounts[date]),
                backgroundColor: 'white'
            }]
        },
        weeklyGrowthChartData: {
            labels: weeklyGrowthData.map(data => data.date),
            datasets: [{
                label: 'Weekly growth %',
                data: weeklyGrowthData.map(data => data.growth),
                fill: false,
                borderColor: 'white'
            }]
        }
    }), [processUserData, weeklyGrowthData])

    return (
        <div className="ap-users-stats-wrapper">
            <div className="ap-unknown-users-header">
                <p className="ap-unknown-users">Users without display names: {unknownUserCount}</p>
                <p className="ap-unknown-users">Showing most recent 50 of {users.length} total users</p>
            </div>
            <div className="ap-users-graphs">
                <div className="ap-users-graph">
                    <h4 className="ap-users-graph-title">Total users</h4>
                    <Line data={chartData.totalUsersOverTimeData} options={chartOptions} />
                </div>
                <div className="ap-users-graph">
                    <h4 className="ap-users-graph-title">New users / day</h4>
                    <Bar data={chartData.totalUsersPerDayData} options={chartOptions} />
                </div>
                <div className="ap-users-graph">
                    <h4 className="ap-users-graph-title">Weekly growth %</h4>
                    <Line data={chartData.weeklyGrowthChartData} options={chartOptions} />
                </div>
            </div>
            <div className="ap-users-list-wrapper">
                <div className="ap-users-list-header">
                    <p className="ap-users-list-header-text ap-user-pic-name-header">User</p>
                    <p className="ap-users-list-header-text ap-user-timestamp">Joined</p>
                    <p className="ap-users-list-header-text ap-user-favs">Favs</p>
                    <p className="ap-users-list-header-text ap-user-groups">Groups</p>
                    <p className="ap-users-list-header-text ap-user-group-details">Details</p>
                    <p className="ap-users-list-header-text ap-user-schedules">Schedules</p>
                    <p className="ap-users-list-header-text ap-user-recents">Recents</p>
                    <p className="ap-users-list-header-text ap-user-email">Email</p>
                    <p className="ap-users-list-header-text ap-user-id">ID</p>
                </div>
                {users.slice(0, 50).map(user => {
                    let numUserFavorites = user.favorites ? Object.keys(user.favorites).length : 0
                    let numUserGroups = user.groups ? Object.keys(user.groups).length : 0
                    let numUserRecents = user.totalRecents ? user.totalRecents : 0

                    return (
                        <div key={user.uid} className="ap-user-row">
                            <div className="ap-user-pic-name">
                                <img src={user.picture?.lowRes} className="ap-user-pic" alt="User pic"/>
                                <p className="ap-user-name">{user.displayName}</p>
                            </div>
                            <p className="ap-user-timestamp">{new Date(user.createdAt).toLocaleString("en-US", { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</p>
                            <p className={`ap-user-favs ${numUserFavorites === 0 ? 'ap-empty' : ''}`}>{numUserFavorites}</p>
                            <p className={`ap-user-groups ${numUserGroups === 0 ? 'ap-empty' : ''}`}>{numUserGroups}</p>
                            <div className="ap-user-group-details">
                                {user.groupsDetails && (
                                    <div className="ap-ugd-wrapper">
                                        {user.groupsDetails.map(group => (
                                            <div className="ap-ugd" key={group.groupId}>
                                                <div className="ap-ugd-flex-sb">
                                                    <div className="ap-ugd-flex">
                                                        <div 
                                                            className="ap-ugd-groupname"
                                                            onClick={(e) => handleNavigate(e, `/${group.festivalId}/g/${group.groupId}`)}
                                                        >
                                                            "{group.groupName}"

                                                        </div>
                                                        <div className="ap-ugd-membercount">&#x28;{group.memberNames.length}&#x29;</div>
                                                    </div>
                                                    <div className="ap-ugd-festival">{group.festivalId}</div>
                                                </div>
                                                <div className="ap-ugd-members">{group.memberNames.join(', ')}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="ap-user-schedules"> {/* New column */}
                                {user.scheduleDetails && user.scheduleDetails.length > 0 && (
                                    <div className="ap-user-schedules-wrapper">
                                        {user.scheduleDetails.map(({ festivalId, selectionCounts }) => (
                                            <div key={festivalId} className="ap-user-schedule-details">
                                                <div className="ap-user-schedule-festival">{festivalId}:</div>
                                                <div className="ap-user-schedule-selections">
                                                    <span>{selectionCounts.total} &#x28;{selectionCounts.count1}, {selectionCounts.count2}&#x29;</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <p className={`ap-user-recents ${numUserRecents === 0 ? 'ap-empty' : ''}`}>{numUserRecents}</p>
                            <p className="ap-user-email">{user.email}</p>
                            <p className="ap-user-id">{user.uid}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

const Admin = ({ currentUser }) => {
    const [users, setUsers] = useState([])
    const [displayUsers, setDisplayUsers] = useState([])
    const [unknownUserCount, setUnknownUserCount] = useState(0)
    const [festivals, setFestivals] = useState([])
    const [selectedTab, setSelectedTab] = useState(0) // 0: Users, 1: Festivals, 2: New blast
    const [blastSubject, setBlastSubject] = useState('')
    const [blastBody, setBlastBody] = useState('')
    const [blastTags, setBlastTags] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate()

    // Add loading states to prevent unnecessary renders
    const [isLoadingUsers, setIsLoadingUsers] = useState(true)
    const [isLoadingFestivals, setIsLoadingFestivals] = useState(true)

    // Separate useEffect for auth check
    useEffect(() => {
        if (!currentUser || currentUser.displayName !== "famousjenga") {
            navigate("/", { replace: true })
        }
    }, [currentUser, navigate])

    // Separate useEffect for data fetching
    useEffect(() => {
        const loadData = async () => {
            try {
                // Load users
                setIsLoadingUsers(true)
                const { users: usersData, unknownUserCount } = await fetchAllUsers()
                const sortedUsers = [...usersData].sort((a, b) => {
                    const dateA = new Date(Number(a.createdAt) || a.createdAt)
                    const dateB = new Date(Number(b.createdAt) || b.createdAt)
                    return dateB - dateA
                })
                setUsers(sortedUsers)
                setDisplayUsers(sortedUsers.slice(0, 50))
                setUnknownUserCount(unknownUserCount)
                setIsLoadingUsers(false)

                // Load festivals
                setIsLoadingFestivals(true)
                const festivalsData = await fetchAdminFestivalData()
                setFestivals(festivalsData)
                setIsLoadingFestivals(false)
            } catch (error) {
                console.error('Error loading data:', error)
                setIsLoadingUsers(false)
                setIsLoadingFestivals(false)
            }
        }

        loadData()
    }, []) // Empty dependency array - only runs once on mount

    const handleNavigate = (event, path) => {
        if (event.ctrlKey || event.metaKey) {
            window.open(path, '_blank')
        } else {
            navigate(path)
        }
    }

    const availableTags = [
        'lineup_update',
        'new_feature',
        'bug_fix',
        'announcement',
        'important',
    ]

    const handleCreateBlast = async () => {
        if (!blastSubject.trim() || !blastBody.trim()) {
            alert('Please fill out both subject and body')
            return
        }

        setIsSubmitting(true)
        try {
            // Get the next announcement number
            const announcementsRef = ref(database, 'announcements')
            const snapshot = await get(announcementsRef)
            const currentAnnouncements = snapshot.val() || {}
            const nextAnnouncementNumber = Object.keys(currentAnnouncements).length + 1

            // Split the message into paragraphs
            const messageParts = blastBody.split('\n').filter(part => part.trim())
            const messageObject = {}
            messageParts.forEach((part, index) => {
                messageObject[index] = part.trim()
            })

            // Create tags object
            const tagsObject = {}
            blastTags.forEach(tag => {
                tagsObject[tag] = true
            })

            // Create the new announcement
            const newAnnouncement = {
                subject: blastSubject,
                message: messageObject,
                timestamp: Date.now(),
                createdBy: currentUser.uid,
                tags: tagsObject
            }

            // Save to database
            await set(ref(database, `announcements/${nextAnnouncementNumber}`), newAnnouncement)

            // Reset form
            setBlastSubject('')
            setBlastBody('')
            setBlastTags([])
            alert('Blast created successfully!')
        } catch (error) {
            console.error('Error creating blast:', error)
            alert('Error creating blast. Please try again.')
        }
        setIsSubmitting(false)
    }

    return (
        <div className="main-wrapper">
            <div className="ap-page-wrapper">
                <Tabs 
                    tabs={['Users', 'Festivals', 'New blast']} 
                    selectedTab={selectedTab} 
                    setSelectedTab={setSelectedTab} 
                />
                {selectedTab === 0 && !isLoadingUsers && (
                    <UsersStats 
                        users={users} 
                        unknownUserCount={unknownUserCount} 
                        handleNavigate={handleNavigate}
                    />
                )}
                {selectedTab === 1 && !isLoadingFestivals && (
                    <div className="ap-festivals-list-wrapper">
                        <div className="ap-festivals-list-header">
                            <p className="ap-festivals-list-header-text ap-festival-name">Festival</p>
                            <p className="ap-festivals-list-header-text ap-festival-groups">Groups</p>
                            <p className="ap-festivals-list-header-text ap-festival-schedules">Schedules</p>
                            <p className="ap-festivals-list-header-text ap-festival-nonzero">Non-zero</p>
                            <p className="ap-festivals-list-header-text ap-festival-favs">Favorites</p>
                        </div>
                        {festivals.map(festival => {
                            return (
                                <div key={festival.id} className="ap-festival-row">
                                    <p className="ap-festival-name">{festival.name}</p>
                                    <p className={`ap-festival-groups ${festival.numGroups === 0 ? 'ap-empty' : ''}`}>{festival.numGroups}</p>
                                    <p className={`ap-festival-schedules ${festival.numSchedules === 0 ? 'ap-empty' : ''}`}>{festival.numSchedules}</p>
                                    <p className={`ap-festival-nonzero ${festival.numSchedulesWithSelections === 0 ? 'ap-empty' : ''}`}>{festival.numSchedulesWithSelections}</p>
                                    <p className={`ap-festival-favs ${festival.numFavorites === 0 ? 'ap-empty' : ''}`}>{festival.numFavorites}</p>
                                </div>
                            )
                        })}
                    </div>
                )}
                {selectedTab === 2 && (
                    <div className="ap-new-blast-wrapper">
                        <p className="ap-new-blast-header">New blast</p>
                        <div className="ap-new-blast-form">
                            <input 
                                type="text" 
                                placeholder="Subject" 
                                className="input-default" 
                                value={blastSubject}
                                onChange={(e) => setBlastSubject(e.target.value)}
                            />
                            <textarea 
                                placeholder="Body (use new lines for paragraphs)" 
                                className="input-default" 
                                value={blastBody}
                                onChange={(e) => setBlastBody(e.target.value)}
                                rows={6}
                            />
                            <div className="ap-blast-tags">
                                {availableTags.map(tag => (
                                    <label key={tag} className="ap-blast-tag">
                                        <input
                                            type="checkbox"
                                            checked={blastTags.includes(tag)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setBlastTags([...blastTags, tag])
                                                } else {
                                                    setBlastTags(blastTags.filter(t => t !== tag))
                                                }
                                            }}
                                        />
                                        {tag.replace('_', ' ')}
                                    </label>
                                ))}
                            </div>
                            <button 
                                className="btn-primary" 
                                onClick={handleCreateBlast}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sending...' : 'Send blast'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Admin
