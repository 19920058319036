import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import AvatarEditor from 'react-avatar-editor'
import { auth, database, storage } from '../../config/firebase-config'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendPasswordResetEmail } from 'firebase/auth'
import { uploadString, getDownloadURL, ref as storageRef } from 'firebase/storage'
import { ref, set, get } from 'firebase/database'
import { useDropzone } from 'react-dropzone'
import { IconX, IconPlus } from '@tabler/icons-react'
import { resizeImage } from '../../utils/helpers'
import { getOrCreateScheduleForFestival } from '../../services/firebase/databaseService'
import './SignupLoginModal.css'

Modal.setAppElement('#root')

const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    
    return width
}

const getAllLocalStorageSelections = () => {
    let localSelections = {}
    for (let key in localStorage) {
        if (key.startsWith('localBlockSelections_')) {
            const festivalId = key.replace('localBlockSelections_', '')
            try {
                const selections = JSON.parse(localStorage.getItem(key)) || {}
                localSelections[festivalId] = selections
            } catch {
                localSelections[festivalId] = {}
            }
        }
    }
    return localSelections
}

const generatePlaceholderAvatar = (name, size, color1, color2) => {
    const initials = getInitials(name)
    const canvas = document.createElement('canvas')
    canvas.width = size
    canvas.height = size
    const context = canvas.getContext('2d')

    // Create gradient
    const gradient = context.createLinearGradient(0, 0, size, size)
    gradient.addColorStop(0, color1)
    gradient.addColorStop(1, color2)

    // Background gradient
    context.fillStyle = gradient
    context.fillRect(0, 0, size, size)

    // Text color
    context.fillStyle = '#ffffff'
    context.font = `${size / 2}px Lato`
    context.textAlign = 'center'
    context.textBaseline = 'middle'

    // Adjust the text position slightly downward
    context.fillText(initials, size / 2, size / 2)

    return canvas.toDataURL()
}

const getInitials = (name) => {
    const names = name.split(' ')
    const initials = names.map(n => n[0]).join('')
    return initials.toUpperCase()
}

const getRandomColor = (excludeColor1, excludeColor2) => {
    const colors = [
        '#FFA471',
        '#FF52AB',
        '#00A3FF',
        '#FFC299',
        '#FF5162',
        '#1975FF',
        '#FA5AFF'
    ]

    let color
    do {
        color = colors[Math.floor(Math.random() * colors.length)]
    } while (color === excludeColor1 || color === excludeColor2)

    return color
}

const SignupLoginModal = ({ isOpen, onRequestClose, content, setModalContent, preFillEmail, currentFestival }) => {
    const [email, setEmail]                         = useState(preFillEmail)
    const [password, setPassword]                   = useState('')
    const [confirmPassword, setConfirmPassword]     = useState('')
    const [username, setUsername]                   = useState('')

    const [image, setImage]         = useState(null)
    const [scale, setScale]         = useState(1)
    const [noClick, setNoClick]     = useState(false)
    const maxSize = 20 * 1024 * 1024 // 20MB
    const allowedFileTypes = [
        'image/jpeg', 
        'image/jpg', 
        'image/png', 
        'image/gif', 
        'image/bmp', 
        'image/webp', 
        'image/heif', 
        'image/heic', 
        'image/tiff'
    ]
    
    const [emailError, setEmailError]                       = useState(null)
    const [passwordError, setPasswordError]                 = useState(null)
    const [confirmPasswordError, setConfirmPasswordError]   = useState(null)
    const [usernameError, setUsernameError]                 = useState(null)
    const [imageError, setImageError] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [resetEmailSent, setResetEmailSent] = useState(false)

    const editorRef         = useRef(null)
    const emailInputRef     = useRef(null)
    const usernameInputRef  = useRef(null)

    const windowWidth = useWindowWidth()

    useEffect(() => {
        if (preFillEmail) {
            setEmail(preFillEmail)
        }
    }, [preFillEmail])

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                if (content === 'signup' && preFillEmail) {
                    usernameInputRef.current.focus()
                } else if (emailInputRef.current) {
                    emailInputRef.current.focus()
                }
            }, 0)
        }
    }, [isOpen, content])

    useEffect(() => {
        setEmailError(null)
        setPasswordError(null)
        setConfirmPasswordError(null)
        setUsernameError(null)
        setImageError(null)
    }, [content])

    useEffect(() => {
        if (!isOpen) {
            setImage(null)
            setScale(1)
            setNoClick(false)
            setEmail(preFillEmail)
            setPassword('')
            setConfirmPassword('')
            setUsername('')
            setEmailError(null)
            setPasswordError(null)
            setConfirmPasswordError(null)
            setUsernameError(null)
            setImageError(null)
        }
    }, [isOpen])

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0]

        setImage(null)
        setScale(1)

        if (!allowedFileTypes.includes(file.type)) {
            setImageError('Invalid file type')
            setNoClick(false)
            return
        }

        if (file.size > maxSize) {
            setImageError('File size exceeds the maximum limit of 20MB.')
            setNoClick(false)
            return
        }

        if (acceptedFiles.length > 0) {
            setImage(acceptedFiles[0])
            setNoClick(true)
            setImageError(null)
        }
    }

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick,
        noKeyboard: true,
    })

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value))
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const validateUsername = (username) => {
        const usernameRegex = /^[a-zA-Z0-9_]{3,15}$/ // 3-15 characters, alphanumeric and underscores
        return usernameRegex.test(username)
    }

    const checkUsernameExists = async (username) => {
        const usersRef = ref(database, 'users')
        const snapshot = await get(usersRef)
        const users = snapshot.val()
    
        for (const userId in users) {
            if (users[userId].displayName === username) {
                return true
            }
        }
        return false
    }

    const handleSignup = async () => {
        setIsLoading(true)
        let valid = true
    
        if (!validateEmail(email)) {
            setEmailError('Invalid email address')
            valid = false
        } else {
            setEmailError(null)
        }
    
        if (email.length === 0) {
            setEmailError('Email is required')
            valid = false
        }
    
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match')
            setPasswordError('Passwords do not match')
            valid = false
        } else {
            setConfirmPasswordError(null)
            setPasswordError(null)
        }
    
        if (password.length < 6) {
            setPasswordError('Password must be at least 6 characters')
            setConfirmPasswordError(null)
            valid = false
        } else {
            setPasswordError(null)
        }
    
        if (!username) {
            setUsernameError('Username is required')
            valid = false
        } else if (!validateUsername(username)) {
            setUsernameError('Username must be 3-15 characters, alphanumeric and underscores only')
            valid = false
        } else if (await checkUsernameExists(username)) {
            setUsernameError('Username is already taken')
            valid = false
        } else {
            setUsernameError(null)
        }
    
        if (!valid) {
            setIsLoading(false)
            return
        }

        let user = null
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            const user = userCredential.user
    
            let imageUrl = ''
            if (image) {
                if (editorRef.current) {
                    const editorImage = editorRef.current.getImage()

                    // Create high-res image (512x512)
                    const highResCanvas = document.createElement('canvas')
                    highResCanvas.width = 512
                    highResCanvas.height = 512
                    const highResContext = highResCanvas.getContext('2d')
                    highResContext.clearRect(0, 0, highResCanvas.width, highResCanvas.height)
                    highResContext.drawImage(editorImage, 0, 0, 512, 512)
                    const highResDataUrl = highResCanvas.toDataURL()
            
                    const highResRef = storageRef(storage, `users/${user.uid}/profile_512.jpg`)
                    await uploadString(highResRef, highResDataUrl, 'data_url')
                    const highResUrl = await getDownloadURL(highResRef)
            
                    // Create low-res image (64x64)
                    const lowResCanvas = document.createElement('canvas')
                    lowResCanvas.width = 64
                    lowResCanvas.height = 64
                    const lowResContext = lowResCanvas.getContext('2d')
                    lowResContext.clearRect(0, 0, lowResCanvas.width, lowResCanvas.height)
                    lowResContext.drawImage(editorImage, 0, 0, 64, 64)
                    const lowResDataUrl = lowResCanvas.toDataURL()
            
                    const lowResRef = storageRef(storage, `users/${user.uid}/profile_64.jpg`)
                    await uploadString(lowResRef, lowResDataUrl, 'data_url')
                    const lowResUrl = await getDownloadURL(lowResRef)
            
                    imageUrl = { highRes: highResUrl, lowRes: lowResUrl }
                }
            } else {
                // Generate a placeholder avatar if no image is uploaded
                const color1 = getRandomColor()
                const color2 = getRandomColor(color1)
    
                const highResAvatar = generatePlaceholderAvatar(username, 512, color1, color2)
                const lowResAvatar = generatePlaceholderAvatar(username, 64, color1, color2)
    
                const highResRef = storageRef(storage, `users/${user.uid}/high_res.jpg`)
                await uploadString(highResRef, highResAvatar, 'data_url')
                const highResUrl = await getDownloadURL(highResRef)
    
                const lowResRef = storageRef(storage, `users/${user.uid}/low_res.jpg`)
                await uploadString(lowResRef, lowResAvatar, 'data_url')
                const lowResUrl = await getDownloadURL(lowResRef)
    
                imageUrl = { highRes: highResUrl, lowRes: lowResUrl }
            }
    
            // Update the user's profile with the display name and photoURL
            await updateProfile(user, {
                displayName: username,
                photoURL: imageUrl.highRes
            })
    
            // Save additional user information to the database
            await saveUserData(user.uid, username, email, imageUrl)

            window.location.reload()

            // Retrieve all local storage selections
            const localSelections = getAllLocalStorageSelections()

            // Create schedules for each festival in local storage
            for (const festivalId in localSelections) {
                const selections = localSelections[festivalId]
                const scheduleId = await getOrCreateScheduleForFestival(user.uid, festivalId)
                await set(ref(database, `schedules/${scheduleId}/selections`), selections)
            }

            setIsLoading(false)
        } catch (error) {
            console.error('Error during signup process:', error)

            // If user was created in Firebase Auth but data saving failed, clean up
            if (user) {
                await auth.deleteUser(user.uid)
                setEmailError('Error creating account. Please try again.')
            }

            if (error.code === 'auth/email-already-in-use') {
                setEmailError('Email is already in use')
            } else {
                setEmailError(error.message)
            }
            setIsLoading(false)
        }
    }

    const saveUserData = async (uid, username, email, imageUrl, retryCount = 3) => {
        const userRef = ref(database, `users/${uid}`)
        const userData = {
            displayName: username,
            email: email,
            picture: imageUrl,
            createdAt: Date.now()
        }
    
        try {
            await set(userRef, userData)

            // Verify that the data was saved correctly
            const savedDataSnapshot = await get(userRef)
            if (savedDataSnapshot.exists()) {
                return true
            } else {
                throw new Error('Data not saved properly')
            }
        } catch (error) {
            console.error(`Attempt failed: ${error.message}`)
        }
    }
    

    const handleLogin = async () => {
        setIsLoading(true)
        let valid = true

        if (!validateEmail(email)) {
            setEmailError('Invalid email address')
            valid = false
        } else {
            setEmailError(null)
        }

        if (email.length === 0) {
            setEmailError('Email is required')
            valid = false
        }

        if (password.length === 0) {
            setPasswordError('Password is required')
            valid = false
        } else {
            setPasswordError(null)
        }

        if (!valid) {
            setIsLoading(false)
            return
        }
        
        try {
            // Sign in the user
            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            
            // If we're logging in from a festival context (preFillEmail is usually set in this case),
            // ensure the user has a schedule
            if (currentFestival) {
                await getOrCreateScheduleForFestival(userCredential.user.uid, currentFestival.id)
            }
            
            onRequestClose()
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                setPasswordError('Incorrect password')
            } else if (error.code === 'auth/user-not-found') {
                setEmailError('No user found with this email')
            } else if (error.code === 'auth/invalid-credential') {
                setPasswordError('Email or password is incorrect')
            } else {
                setEmailError(error.message)
            }
        }
        setIsLoading(false)
    }

    const handlePasswordReset = async () => {
        if (!email) {
            setEmailError('Please enter your email address to reset your password')
            return
        }
    
        try {
            await sendPasswordResetEmail(auth, email)
            setResetEmailSent(true)
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                setEmailError('No user found with this email')
            } else if (error.code === 'auth/invalid-email') {
                setEmailError('Invalid email address')
            } else {
                setEmailError('Failed to send password reset email')
            }
        }
    }

    const hasErrors = emailError || usernameError || passwordError || confirmPasswordError

    let modalHTML = <></>
    if (content === "signup") {
        modalHTML = (
            <div className="modal-signup">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Sign up</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                <div className="dropzone-wrapper">
                    <div {...getRootProps({ className: `signup-dropzone ${image ? 'no-hover' : ''}` })}>
                        <input {...getInputProps()} />
                        {image ? (
                            <div className="circular-container">
                                <AvatarEditor
                                    ref={editorRef}
                                    image={image}
                                    width={windowWidth <= 1000 ? 100 : 160}
                                    height={windowWidth <= 1000 ? 100 : 160}
                                    border={0}
                                    borderRadius={160}
                                    color={[255, 255, 255, 0.6]}
                                    scale={scale}
                                    rotate={0}
                                />
                            </div>
                        ) : (
                            <IconPlus size={48} strokeWidth={0.5} className="icon-plus-dropzone" />
                        )}
                    </div>
                </div>
                {image && 
                    <div className="signup-slider-container">
                        <p className="body-s">1x</p>
                        <input
                            id="scale-slider"
                            type="range"
                            value={scale}
                            min="1"
                            max="5"
                            step="0.01"
                            onChange={handleScaleChange}
                            style={{ width: '100%' }}
                        />
                        <p className="body-s">5x</p>
                    </div>
                }
                <div className="txt-btn-photo-action" onClick={open}>
                    <p>{image ? 'Change photo' : 'Add photo'}</p>
                </div>
                {imageError && <p className="error-message">{imageError}</p>}
                <div className="modal-signup-inputs">
                    <div className="input-error-wrapper">
                        <input 
                            type="email" 
                            ref={emailInputRef}
                            className={`input-default ${emailError ? 'input-error' : ''}`}
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            onFocus={() => setEmailError(null)}
                        />
                        {emailError && <p className="error-message">{emailError}</p>}
                    </div>
                    <div className="input-error-wrapper">
                        <input 
                            type="text" 
                            ref={usernameInputRef}
                            className={`input-default ${usernameError ? 'input-error' : ''}`}
                            placeholder="Username" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)}
                            onFocus={() => setUsernameError(null)}
                        />
                        {usernameError && <p className="error-message">{usernameError}</p>}
                    </div>
                    <div className="input-error-wrapper">
                        <input 
                            type="password" 
                            className={`input-default ${passwordError ? 'input-error' : ''}`}
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            onFocus={() => setPasswordError(null)}
                        />
                        {passwordError && <p className="error-message">{passwordError}</p>}
                    </div>
                    <div className="input-error-wrapper">
                        <input 
                            type="password" 
                            className={`input-default ${confirmPasswordError ? 'input-error' : ''}`}
                            placeholder="Confirm Password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            onFocus={() => setConfirmPasswordError(null)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSignup()
                                }
                            }}
                        />
                        {confirmPasswordError && <p className="error-message">{confirmPasswordError}</p>}
                    </div>
                </div>
                <div className="modal-btn-signup-wrapper">
                    <button className="btn-primary" onClick={handleSignup} disabled={hasErrors}>
                        {isLoading ? 'Signing up...' : 'Sign up'}
                    </button>
                </div>
                <div className="modal-signup-bottom">
                    <p className="body-s opacity50">Already have an account?</p>
                    <div className="btn-text-blue" onClick={() => setModalContent('login')}>Log in</div>
                </div>
            </div>
        )
    } else if (content === "login") {
        modalHTML = (
            <div className="modal-signup">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Log in</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                <div className="modal-signup-inputs">
                    <div className="input-error-wrapper">
                        <input 
                            type="email" 
                            ref={emailInputRef}
                            className={`input-default ${emailError ? 'input-error' : ''}`}
                            placeholder="Email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            onFocus={() => setEmailError(null)}
                        />
                        {emailError && <p className="error-message">{emailError}</p>}
                    </div>
                    <div className="input-error-wrapper">
                        <input 
                            type="password" 
                            className={`input-default ${passwordError ? 'input-error' : ''}`}
                            placeholder="Password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            onFocus={() => setPasswordError(null)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleLogin()
                                }
                            }}
                        />
                        <div className="password-reset-wrapper">
                            {passwordError && <p className="error-message">{passwordError}</p>}
                            <p 
                                className="btn-text-blue sm" 
                                onClick={() => {
                                    setResetEmailSent(false)
                                    setModalContent('reset')
                                }}
                            >Forgot password?</p>
                        </div>
                    </div>
                </div>
                <div className="modal-btn-signup-wrapper">
                    <button className="btn-primary" onClick={handleLogin} disabled={hasErrors}>
                        {isLoading ? 'Logging in...' : 'Log in'}
                    </button>
                </div>
                <div className="modal-signup-bottom">
                    <p className="body-s opacity50">Don't have an account?</p>
                    <div className="btn-text-blue" onClick={() => setModalContent('signup')}>Sign up</div>
                </div>
            </div>
        )
    } else {
        modalHTML = (
            <div className="modal-signup">
                <div className="modal-header">
                    <div className="modal-title-wrapper">
                        <div className="nav-logo-circle" />
                        <h3>Reset password</h3>
                    </div>
                    <IconX className="icon-modal-close" size={24} onClick={onRequestClose} />
                </div>
                {resetEmailSent 
                    ? (
                        <div className="modal-signup-inputs">
                            <p className="opacity80">A password reset link was sent to your email.</p>
                        </div>
                    ) : (
                        <>
                            <div className="modal-signup-inputs">
                                <p className="opacity80">A password reset link will be sent to the email below.</p>
                                <div className="input-error-wrapper">
                                    <input 
                                        type="email" 
                                        ref={emailInputRef}
                                        className={`input-default ${emailError ? 'input-error' : ''}`}
                                        placeholder="Email" 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} 
                                        onFocus={() => setEmailError(null)}
                                    />
                                    {emailError && <p className="error-message">{emailError}</p>}
                                </div>
                            </div>
                            <div className="modal-btn-signup-wrapper">
                                <button className="btn-primary" onClick={handlePasswordReset} disabled={hasErrors}>
                                    Send
                                </button>
                            </div>
                        </>
                    )}
                <div className="modal-signup-bottom">
                    <div className="btn-text-blue" onClick={() => setModalContent('login')}>Back to login</div>
                </div>
            </div>
        )
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal-wrapper"
            overlayClassName="modal-overlay"
        >
            {modalHTML}
        </Modal>
    )
}

export default SignupLoginModal
