import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { fetchGroupDataById, addMemberToGroup, getOrCreateScheduleForFestival } from "../../services/firebase/databaseService"
import './Group.css'
import './NewGroup.css'
import SignupLoginModal from "../Nav/SignupLoginModal"
import { ref, get } from 'firebase/database'
import { database } from "../../config/firebase-config"

const JoinGroup = ({ currentUser, currentFestival }) => {
    const { groupId } = useParams()
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('signup') // 'signup' or 'login'
    const [email, setEmail] = useState('')
    const openModal = (content) => {
        setModalContent(content)
        setIsModalOpen(true)
    }
    const closeModal = () => setIsModalOpen(false)

    const [isJoining, setIsJoining] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [groupData, setGroupData] = useState(null)

    useEffect(() => {
        if (currentFestival && groupId) {
            async function loadGroupData() {
                setIsLoading(true)
                try {
                    const data = await fetchGroupDataById(groupId)
                    setGroupData(data)

                    if (currentUser) {
                        // First ensure user has a schedule for this festival
                        await getOrCreateScheduleForFestival(currentUser.uid, currentFestival.id)
                        
                        // Then check if they're already a member
                        if (data.members && currentUser.uid in data.members) {
                            navigate(`/${currentFestival.id}/g/${groupId}`)
                        }
                    }
                    setIsLoading(false)
                } catch (error) { 
                    if (error.message === "Group not found") {
                        setIsLoading(false)
                    }
                }
            }
            loadGroupData()
        } else {
            setGroupData(null)
            setIsLoading(false)
        }
    }, [groupId, currentFestival, currentUser])

    const handleJoinGroup = async () => {
        setIsJoining(true)
        try {
            // Ensure the user has a schedule for the festival
            const scheduleId = await getOrCreateScheduleForFestival(currentUser.uid, currentFestival.id)
            if (!scheduleId) {
                throw new Error("Failed to create or get schedule")
            }
            
            // Add the user to the group
            await addMemberToGroup(groupId, currentUser.uid, currentFestival.id)
            
            // Wait a moment to ensure Firebase data is synchronized
            await new Promise(resolve => setTimeout(resolve, 1000))
            
            // Force a full page reload to ensure all data is fresh
            window.location.href = `/${currentFestival.id}/g/${groupId}`
            
        } catch (error) {
            console.error('Error joining group:', error)
            setIsJoining(false)
        }
    }

    if (currentFestival && groupData) {
        return (
            <React.Fragment>
                <div className="main-wrapper fade-in-content-no-scale">
                    <div className="mini-header">
                        <div className="mini-header-contents">
                            <p>Join group</p>
                            <div className="separator-dot" />
                            <p>{currentFestival.name}</p>
                        </div>
                    </div>
                    <div className="div-center">
                        <div className="join-group-wrapper">
                            <div className="join-group-header-text-wrapper">
                                <div className="join-group-header-text">You're invited!</div>
                                <p className="join-group-subtext">{currentFestival.name} group</p>
                            </div>
                            <img className="img-group-join" src={groupData.picture} alt={groupData.name} />
                            <div className="join-group-header-text join-group-name">{groupData.name}</div>
                            <div className="join-group-members-wrapper">
                                <p>{groupData.memberCount} joined</p>
                            </div>
                            {currentUser && (
                                <button className="btn-primary btn-join-group" onClick={handleJoinGroup} >
                                    {isJoining ? 'Joining...' : 'Join group'}
                                </button>
                            )}
                            {!currentUser && (
                                <button className="btn-primary btn-join-group" onClick={() => openModal('signup')} >
                                    Sign up to join!
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <SignupLoginModal 
                    isOpen={isModalOpen} 
                    onRequestClose={closeModal} 
                    content={modalContent} 
                    setModalContent={setModalContent} 
                    preFillEmail={email}
                    currentFestival={currentFestival}
                />
            </React.Fragment>
        )
    }
}

export default JoinGroup